import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import "aos/dist/aos.css"
import NewsStrip from "../components/news-strip"
import MetroFares from "../components/metro-fares"

class LC2ServicePage extends Component {
  componentDidMount() {
    AOS.init()
    window.addEventListener("load", AOS.refresh)
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000)
  }

  componentDidUpdate() {
    AOS.refresh()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="LC2"
          description="View route information and buy your ticket for the LC2 service operated by Tetley's Coaches."
        />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent">
            <div className="text-center">
              <h1 className="page-content-heading">LC2 service</h1>
              <p>Harehills Road - Leeds City Academy</p>
              <p>Leeds City Academy - Harehills Road</p>
            </div>
          </div>

          <div className="body gutter page-content-parent">
            <div className="page-content">
              <div className="page-content__text">
                <h2>Route</h2>
                <p style={{ margin: '15px 0'}}>This service will pick up/set down at all regular bus stops along the following route:</p>
                <h3 style={{ margin: '10px 0'}}>AM route</h3>
                <iframe
                  title="LC2 AM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=15YYbZYffO7V6qyCORGcxJJdPkWp21KTX"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>Towards</strong> Leeds City Academy
                </p>
                <p>
                  From Harehills Road at Ashley Road then all stops via Harehills Road, Roundhay Road, Spencer Place, Cowper Street, Chapeltown Road, Harrogate Road, Potternewton Lane, Stainbeck Avenue, Stonegate Road, Meanwood Road, Cross Chancellor Street, Melville Road, Woodhouse Street, Rampart Road, Drop off on Woodhouse Street.
                </p>

                <h3 style={{ margin: '10px 0'}}>PM route</h3>
                <iframe
                  title="LC2 PM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1a_xyjTq5qpYdOEfz1JMVOqjhoN_rqsvM"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>From</strong> Leeds City Academy
                </p>
                <p>
                  Pick up at Woodhouse Street, Rampart Road, Woodhouse Street, Melville Road, Cross Chancellor Street, Meanwood Road, Stonegate Road, Stainbeck Avenue, Potternewton Lane, Harrogate Road, Chapeltown Road, Cowper Street, Spencer Place, Roundhay Road, Harehills Road at Ashley Road.
                </p>
              </div>
              <div className="page-content__text">
                <h2>Timetable</h2>
                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Morning</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Harehills Road/Ashley Road</td>
                      <td>DEP</td>
                      <td>0740</td>
                    </tr>
                    <tr>
                      <td>Cowper Street</td>
                      <td>DEP</td>
                      <td>0747</td>
                    </tr>
                    <tr>
                      <td>Meanwood Health Centre</td>
                      <td>DEP</td>
                      <td>0759</td>
                    </tr>
                    <tr>
                      <td>Leeds City Academy</td>
                      <td>ARR</td>
                      <td>0810</td>
                    </tr>
                  </tbody>
                </table>

                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Afternoon</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Leeds City Academy</td>
                      <td>DEP</td>
                      <td>1515</td>
                    </tr>
                    <tr>
                      <td>Meanwood Health Centre</td>
                      <td>DEP</td>
                      <td>1526</td>
                    </tr>
                    <tr>
                      <td>Chapeltown Rd/Louis St</td>
                      <td>DEP</td>
                      <td>1538</td>
                    </tr>
                    <tr>
                      <td>Harehills Rd/Ashley Rd</td>
                      <td>ARR</td>
                      <td>1545</td>
                    </tr>
                  </tbody>
                </table>

                <h2 style={{ margin: '0 0 20px 0'}}>24/25 fares</h2>
                <p>There is only one payment option for this service:</p>
                <ul>
                  <li><strong>Single one way fare -  £1.</strong></li>
                  <li>Drivers accept cash and contactless payments.</li>
                  <li><strong>PLEASE NOTE:</strong> Metro pre paid passes will not be accepted on this service from September 2022.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <NewsStrip />
      </Layout>
    )
  }
}

export default LC2ServicePage
